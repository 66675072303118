import React from 'react';

export const FENCE_TYPE_GATE_SELF_SUPPORTING = 'gateSelfSupporting';
export const FENCE_TYPE_GATE_TILTING = 'gateTilting';
export const FENCE_TYPE_GATEWAY = 'gateway';
export const FENCE_TYPE_SPAN = 'span';

export const fencesTypes = [
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN,
];

export default fencesTypes;
