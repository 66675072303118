import {combineReducers} from 'redux';
import {SET_BLOCK, SET_CHOSEN_OPTION, SET_ERROR_RESPONSE, SET_HEIGHT, SET_MODEL, SET_RESPONSE} from './actions';

const appInitialState = {
  userInput : {
    chosenOption: null,
    model: null,
    block: null,
    height: null,
  },
  response: null,
  errorResponse: null,
};

const app = (state = appInitialState, action) => {
  switch (action.type) {
    case SET_HEIGHT:
      return {...state, height: action.height};
    case SET_MODEL:
      return {...state, model: action.model};
    case SET_BLOCK:
      return {...state, block: action.block};
    case SET_RESPONSE:
      return {...state, response: action.response};
    case SET_ERROR_RESPONSE:
      return {...state, errorResponse: action.errorResponse};
    case SET_CHOSEN_OPTION:
      return {...state, chosenOption: action.chosenOption};
    default:
      return state
  }
};

const appReducer = combineReducers({
  app,
});

export default appReducer;
