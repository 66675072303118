import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PdfHtml from './PdfHtml';
import {useSelector} from "react-redux";

const PdfBtn = () => {
  const model = useSelector(state => state.app.model);
  const block = useSelector(state => state.app.block);
  const height = useSelector(state => state.app.height);
  const response = useSelector(state => state.app.response);

  const PdfData = ({model, block, height, response}) => {
    const style = {
      width: '100%',
      float: 'left',
      textAlign: 'center',
      marginTop: '50px',
      marginBottom: '75px',
    };

    const styleTableChosenData = {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '15px',
    };

    const styleDataTableFirstColumn = {
      fontWeight: 'bold',
    };

    const styleDataTableSecondColumn = {
      textAlign: 'right',
    };

    const styleImageOne = {
      display: 'block',
      width: '1000px',
      height: 'auto',
      marginTop: '100px',
      marginLeft: 'auto',
      marginRight: 'auto',
    };

    const styleImageTwo = {
      display: 'block',
      width: '1150px',
      height: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '185px',
      pageBreakBefore: 'always'
    };

    return (
        <>
          <div style={style}>
            <h1>SPECYFIKACJA</h1>
          </div>
          <div>
            <table style={styleTableChosenData}>
              <tr>
                <td style={styleDataTableFirstColumn}>Wybrany model:</td>
                <td style={styleDataTableSecondColumn}>model {model}</td>
              </tr>
              <tr>
                <td style={styleDataTableFirstColumn}>Wybrany rodzaj bloczków:</td>
                <td style={styleDataTableSecondColumn}>{block}</td>
              </tr>
              <tr>
                <td style={styleDataTableFirstColumn}>Wybrana wysokość:</td>
                <td style={styleDataTableSecondColumn}>{height} mm</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style={styleDataTableFirstColumn}>Wysokość przęsła:</td>
                <td style={styleDataTableSecondColumn}>{response.spanHeight}</td>
              </tr>
              <tr>
                <td style={styleDataTableFirstColumn}>Wysokość furtki:</td>
                <td style={styleDataTableSecondColumn}>{response.gatewayHeight}</td>
              </tr>
              <tr>
                <td style={styleDataTableFirstColumn}>{response.gateType}:</td>
                <td style={styleDataTableSecondColumn}>{response.gateHeight}</td>
              </tr>
              <tr>
                <td style={styleDataTableFirstColumn}>{response.extraGateType}:</td>
                <td style={styleDataTableSecondColumn}>{response.extraGateHeight}</td>
              </tr>
            </table>
          </div>
          <div>
            <img
                alt={''}
                style={styleImageOne}
                 src={response.visualisation}/>
          </div>
          <div>
            <img
                alt={''}
                style={styleImageTwo}
                src={response.technical}/>
          </div>
        </>
    );
  };

  const WrappedResponse = () => {
    const style = {
      pageBreakBefore: 'always',
    };

    return (
        <div style={style}>
          <PdfData model={model} block={block} height={height} response={response}/>
        </div>
    );
  };

  const click = (e) => {
    e.preventDefault();

    const htmlResponses = ReactDOMServer.renderToStaticMarkup(<WrappedResponse/>);
    const html = PdfHtml(htmlResponses);

    // const newWindow = window.open();
    // newWindow.document.write(html);

    fetch('https://www.api.pdf.konsport.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/html; charset=utf-8',
        'X-Project-Name': 'CLEARANCE',
        'X-Pdf-Name': `stale-wysokosci-bloczki`,
        'X-Orientation': 'landscape',
      },
      body: html,
    }).then((response) => response.json()).then((response) => {
      window.open(response.url);
    }).catch((err) => {
      console.error(err);
    })
  };

  return (
      <button
          id={'pdfBtn'}
          type={'button'}
          className={'downloadPdf'}
          onClick={click}
      >
        Pobierz PDF
      </button>
  );
};

export default PdfBtn;
