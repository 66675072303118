import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import './RangeBtns.css';
import {useDispatch, useSelector} from "react-redux";
import {modelsData} from "../../ModelsConstants";
import {SET_RESPONSE} from "../../actions";

const RangeBtns = () => {
    const response = useSelector(state => state.app.response);
    const model = useSelector(state => state.app.model);

    const dispatch = useDispatch();

    const [upLocked, setUpLocked] = useState(false);
    const [downLocked, setDownLocked] = useState(false);

    const DIRECTION_UP = 'up';
    const DIRECTION_DOWN = 'down';

    const TYPE_SPAN = 'span';
    const largestSpan = modelsData[TYPE_SPAN][model].length - 1;

    const changeButtonsText = () => {
        if (modelsData[TYPE_SPAN][model].indexOf(response[TYPE_SPAN]['closestHeight']) === 0) {
            return `Nie można już zmniejszyć wysokości.`;
        } else if (modelsData[TYPE_SPAN][model].indexOf(response[TYPE_SPAN]['closestHeight']) === largestSpan) {
            return `Nie można już zwiększyć wysokości.`;
        } else {
            return `Kliknij w przycisk po prawej by pokazać inną linię ogrodzenia.`;
        }
    };

    const goToNextClosestHeight = (direction) => {
        const newResponse = {...response};

        const getNextIndex = (direction, index) => {
            switch (direction) {
                case DIRECTION_UP:
                    return index + 1;
                case DIRECTION_DOWN:
                    return index - 1;
                default:
                    throw new Error();
            }
        };

        const blockButton = (direction) => {
            switch (direction) {
                case DIRECTION_UP:
                    return setUpLocked(true);
                case DIRECTION_DOWN:
                    return setDownLocked(true);
                default:
                    throw new Error();
            }
        };

        Object.keys(response).map((fenceType) => {
            const currentClosestHeight = response[fenceType]['closestHeight'];
            const currentIndex = modelsData[fenceType][model].indexOf(currentClosestHeight);
            const nextIndex = getNextIndex(direction, currentIndex);

            if (modelsData[fenceType][model][nextIndex]) {
                setUpLocked(false);
                setDownLocked(false);
                newResponse[fenceType]['closestHeight'] = modelsData[fenceType][model][nextIndex];
            } else {
                blockButton(direction)
            }
        });

        dispatch({type: SET_RESPONSE, response: newResponse});
    };

    return (
        <div id={'rangeBtns'}>
            <span>{changeButtonsText()}</span>
            <button
                className={'btn rangeBtn'}
                type={'button'}
                onClick={() => goToNextClosestHeight(DIRECTION_UP)}
                disabled={upLocked}
            >
                <FontAwesomeIcon icon={faChevronUp}/>
            </button>
            <button
                className={'btn rangeBtn'}
                type={'button'}
                onClick={() => goToNextClosestHeight(DIRECTION_DOWN)}
                disabled={downLocked}
            >
                <FontAwesomeIcon icon={faChevronDown}/>
            </button>
        </div>
    );
};

RangeBtns.propTypes = {};

export default RangeBtns;
