import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN
} from "./FenceTypesConstants";

export const MODEL_P64 = 'P64';
export const MODEL_PP002P = 'PP002P';
export const MODEL_P82 = 'P82';
export const MODEL_P82MIX = 'P82MIX';
export const MODEL_P102 = 'P102';
export const MODEL_PS004 = 'PS004';

export const models = [
  MODEL_P64,
  MODEL_PP002P,
  MODEL_P82,
  MODEL_P82MIX,
  MODEL_P102,
  MODEL_PS004,
];

const modelsDataGates = {
  P64: [1042, 1142, 1242, 1342, 1442, 1542, 1642, 1742, 1842, 1942, 2042],
  PP002P: [1027, 1112, 1197, 1282, 1367, 1452, 1537, 1622, 1707, 1792, 1877, 1962, 2047],
  P82: [1017, 1112, 1207, 1302, 1397, 1492, 1587, 1682, 1777, 1872, 1967, 2062],
  P82PINO1: [1017, 1112, 1207, 1302, 1397, 1492, 1587, 1682, 1777, 1872, 1967, 2062],
  P82PINO2: [1017, 1112, 1207, 1302, 1397, 1492, 1587, 1682, 1777, 1872, 1967, 2062],
  P82NOCE1: [1017, 1112, 1207, 1302, 1397, 1492, 1587, 1682, 1777, 1872, 1967, 2062],
  P82NOCE2: [1017, 1112, 1207, 1302, 1397, 1492, 1587, 1682, 1777, 1872, 1967, 2062],
  P82COLORE1: [1017, 1112, 1207, 1302, 1397, 1492, 1587, 1682, 1777, 1872, 1967, 2062],
  P82COLORE2: [1017, 1112, 1207, 1302, 1397, 1492, 1587, 1682, 1777, 1872, 1967, 2062],
  P82MIX: [1077, 1172, 1267, 1362, 1457, 1552, 1647, 1742, 1837, 1932],
  P102: [1102, 1217, 1332, 1447, 1562, 1677, 1792, 1907, 2022],
  PS004: [1139, 1209, 1279, 1349, 1419, 1489, 1559, 1629, 1699, 1769, 1839, 1909, 1979, 2049, 2119, 2189],
};

export const modelsData = {
  ranges: {
    P64: {min: 42, max: 52,},
    PP002P: {min: 57, max: 57,},
    P82: {min: 47, max: 57,},
    P82MIX: {min: 47, max: 57,},
    P102: {min: 27, max: 57,},
    PS004: {min: 22, max: 52,},
  },
  clearances: {
    P64: {min: 60, max: 70,},
    PP002P: {min: 60, max: 60,},
    P82: {min: 60, max: 70,},
    P82MIX: {min: 60, max: 70,},
    P102: {min: 60, max: 90,},
    PS004: {min: 60, max: 90,},
  },
  [FENCE_TYPE_SPAN]: {
    P64: [360, 460, 560, 660, 760, 860, 960, 1060, 1160, 1260, 1360, 1460, 1560, 1660, 1760, 1860, 1960, 2060],
    PP002P: [350, 435, 520, 605, 690, 775, 860, 945, 1030, 1115, 1200, 1285, 1370, 1455, 1540, 1625, 1710, 1795, 1880, 1965, 2050],
    P82: [270, 365, 460, 555, 650, 745, 840, 935, 1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82PINO1: [650, 745, 840, 935, 1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82PINO2: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82NOCE1: [650, 745, 840, 935, 1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82NOCE2: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82COLORE1: [650, 745, 840, 935, 1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82COLORE2: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82MIX: [615, 710, 805, 900, 995, 1090, 1185, 1280, 1375, 1470, 1565, 1660, 1755, 1850, 1945, 2040, 2135, 2230],
    P102: [330, 445, 560, 675, 790, 905, 1020, 1135, 1250, 1365, 1480, 1595, 1710, 1825, 1940, 2055, 2170],
    PS004: [147, 217, 287, 357, 427, 497, 567, 637, 707, 777, 847, 917, 987, 1057, 1127, 1197, 1267, 1337, 1407, 1477, 1547, 1617, 1687, 1757, 1827, 1897, 1967, 2037],
  },
  [FENCE_TYPE_GATEWAY]: {
    P64: [1060, 1160, 1260, 1360, 1460, 1560, 1660, 1760, 1860, 1960, 2060],
    PP002P: [1030, 1115, 1200, 1285, 1370, 1455, 1540, 1625, 1710, 1795, 1880, 1965, 2050],
    P82: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82PINO1: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82PINO2: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82NOCE1: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82NOCE2: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82COLORE1: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82COLORE2: [1030, 1125, 1220, 1315, 1410, 1505, 1600, 1695, 1790, 1885, 1980, 2075],
    P82MIX: [1090, 1185, 1280, 1375, 1470, 1565, 1660, 1755, 1850, 1945],
    P102: [1020, 1135, 1250, 1365, 1480, 1595, 1710, 1825, 1940, 2055],
    PS004: [1037, 1107, 1177, 1247, 1317, 1387, 1457, 1527, 1597, 1667, 1737, 1807, 1877, 1947, 2017, 2087],
  },
  [FENCE_TYPE_GATE_TILTING]: modelsDataGates,
  [FENCE_TYPE_GATE_SELF_SUPPORTING]: modelsDataGates,
};
