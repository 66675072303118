import {connect} from 'react-redux';
import Project from '../../components/Project/Project';

const mapStateToProps = (state) => ({
  response: state.app.response,
  model: state.app.model,
});

export default connect(
    mapStateToProps,
)(Project);
