import React from 'react';
import {SET_CHOSEN_OPTION} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import './GettingStarted.css'

const GettingStarted = () => {
  const dispatch = useDispatch();

  const chosenOption = useSelector(state => state.app.chosenOption);

  if (chosenOption) {
    return null;
  }

  const handleShowConstantHeights = () => {
    dispatch({type: SET_CHOSEN_OPTION, chosenOption: 'constantHeights'});
  };

  const handleShowConcreteBlocks = () => {
    dispatch({type: SET_CHOSEN_OPTION, chosenOption: 'concreteBlocks'});
  };

  return (
      <section id='gettingStartedPage'>
        <div className={'gettingStartedHeader'}>Stałe wysokości</div>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'containerForMargin'}>
              <div className={'col'} id={'gettingStartedPageChoiceForm'}>
                <button
                    id={'constantHeightsButton'}
                    className={'gettingStartedPageChoiceButton'}
                    onClick={handleShowConstantHeights}
                >
                  Stałe wysokości ogrodzeń na słupkach stalowych
                </button>
                <button
                    id={'concreteBlocksButton'}
                    className={'gettingStartedPageChoiceButton'}
                    onClick={handleShowConcreteBlocks}
                >
                  Stałe wysokości ogrodzeń na przykładowych bloczkach betonowych
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default GettingStarted;