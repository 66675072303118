import React from 'react';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import GettingStarted from "./GettingStarted";
import FormConcreteBlocks from "./FormConcreteBlocks";
import Form from "./Form";
import ConstantHeightsApp from "./ConstantHeightsApp";
import ProjectConcreteBlocks from "../containers/ProjectConcreteBlocks/ProjectConcreteBlocks";

const App = ({}) => {
  return (
      <>
        <GettingStarted/>
        <Form/>
        <FormConcreteBlocks/>
        <ConstantHeightsApp/>
        <ProjectConcreteBlocks/>
        <Footer/>
      </>
  );
};

export default App;
