import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer id={'footer'}>
    <div className={'container-xl'}>
      <div className={'row'} id={'footerRow'}>
        <div className={'col-4'} id={'footerLogoContainer'}>
          <img src={'https://kns-assets-common.s3-eu-west-1.amazonaws.com/img/logo.png'} alt={'logo'} />
        </div>
        <div className={'col-4'} id={'footerRights'}>
          <span>All rights reserved © Konsport</span>
        </div>
        <div className={'col-4'} id={'footerCompany'}>
          <p>Jana Kilińskiego 75, Kazimierz<br></br>Tel: +48 43 677 50 63<br></br>E-mail: biuro@konsport.com.pl</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
