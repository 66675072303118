import React from 'react';
import PropTypes from 'prop-types';
import './ClosestHeights.css';
import {FENCE_TYPE_GATE_SELF_SUPPORTING, FENCE_TYPE_GATE_TILTING, FENCE_TYPE_GATEWAY, FENCE_TYPE_SPAN} from '../../FenceTypesConstants';

const ClosestHeights = ({response}) => {
  return (
    <div id={'closestHeights'}>
      <div id={'closestHeightGateSelfSupporting'} className={'closestHeight'}>
        <p>Najbliższa brama samonośna:<br/>
          <span className={'closestHeightNumber'}>{response[FENCE_TYPE_GATE_SELF_SUPPORTING].closestHeight}&nbsp;mm</span>
        </p>
      </div>
      <div id={'closestHeightGateTilting'} className={'closestHeight'}>
        <p>Najbliższa brama uchylna:<br/>
          <span className={'closestHeightNumber'}>{response[FENCE_TYPE_GATE_TILTING].closestHeight}&nbsp;mm</span>
        </p>
      </div>
      <div id={'closestHeightGateway'} className={'closestHeight'}>
        <p>Najbliższa<br/>furtka:<br/>
          <span className={'closestHeightNumber'}>{response[FENCE_TYPE_GATEWAY].closestHeight}&nbsp;mm</span>
        </p>
      </div>
      <div id={'closestHeightSpan'} className={'closestHeight'}>
        <p>Najbliższe przęsło:<br/>
          <span className={'closestHeightNumber'}>{response[FENCE_TYPE_SPAN].closestHeight}&nbsp;mm</span>
        </p>
      </div>
    </div>
  );
};

ClosestHeights.propTypes = {
  response: PropTypes.object.isRequired,
};

export default ClosestHeights;
