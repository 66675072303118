import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import './FormConcreteBlocks.css';
import {useDispatch, useSelector} from "react-redux";
import {SET_BLOCK, SET_CHOSEN_OPTION, SET_HEIGHT, SET_MODEL, SET_RESPONSE} from "../actions";
import { MODEL_P82, MODEL_PS004 } from '../ModelsConstants'

const BLOCK_ROMA = '360 - Roma';
const BLOCK_GORC = '380 - Gorc';

const FormConcreteBlocks = () => {
  const chosenOption = useSelector(state => state.app.chosenOption);
  const concreteBlocksOption = 'concreteBlocks';

  const model = useSelector(state => state.app.model);
  const block = useSelector(state => state.app.block);
  const height = useSelector(state => state.app.height);

  const dispatch = useDispatch();

  if (chosenOption !== concreteBlocksOption) {
    return null;
  }

  const goBack = () => {
    dispatch({type: SET_CHOSEN_OPTION, chosenOption: null});
    dispatch({type: SET_MODEL, model: ''});
    dispatch({type: SET_HEIGHT, height: null});
    dispatch({type: SET_BLOCK, block: null});
    dispatch({type: SET_RESPONSE, response: null})
  };

  const modelChange = (e) => dispatch({type: SET_MODEL, model: e.target.value});

  const blockChange = (e) => dispatch({type: SET_BLOCK, block: e.target.value});

  const heightChange = (e) => dispatch({type: SET_HEIGHT, height: parseInt(e.target.value)});

  const onSubmit = (e) => {
    e.preventDefault();

    if (!model || !height || !block) {
      const newResponse = null;
      dispatch({type: SET_RESPONSE, response: newResponse});
    } else {

      const getGateType = () => {
        if (model === MODEL_P82) {
          if (block === BLOCK_GORC) {
            return `Wysokość bramy uchylnej`;
          } else if (block === BLOCK_ROMA) {
            return `Wysokość bramy samonośnej`;
          }
        } else if (model === MODEL_PS004) {
          if (block === BLOCK_GORC) {
            return `Wysokość bramy uchylnej`;
          } else if (block === BLOCK_ROMA) {
            return `Wysokość bramy samonośnej`;
          }
        }
      };

      const getExtraGateType = () => {
        if (block === BLOCK_ROMA) {
          if (model === MODEL_PS004 && height === 1600) {
            return null;
          } else {
            return `Wysokość bramy uchylnej`;
          }
        }
      };

      const getSpanHeight = () => {
        if (model === MODEL_P82) {
          if (block === BLOCK_GORC) {
            if (height === 1280) {
              return `850 mm`;
            } else if (height === 1440) {
              return `1030 mm`;
            }
          } else if (block === BLOCK_ROMA) {
            if (height === 1400) {
              return `935 mm`;
            } else if (height === 1600) {
              return `1135 mm`;
            }
          }
        } else if (model === MODEL_PS004) {
          if (block === BLOCK_GORC) {
            if (height === 1280) {
              return `847 mm`;
            } else if (height === 1440) {
              return `987 mm`
            }
          } else if (block === BLOCK_ROMA) {
            if (height === 1400) {
              return `917 mm`;
            } else if (height === 1600) {
              return `1127 mm`;
            }
          }
        }
      };

      const getGatewayHeight = () => {
        if (model === MODEL_P82) {
          if (block === BLOCK_GORC) {
            if (height === 1280) {
              return `1220 mm`;
            } else if (height === 1440) {
              return `1410 mm`;
            }
          } else if (block === BLOCK_ROMA) {
            if (height === 1400) {
              return `1310 mm`;
            } else if (height === 1600) {
              return `1505 mm`;
            }
          }
        } else if (model === MODEL_PS004) {
          if (block === BLOCK_GORC) {
            if (height === 1280) {
              return `1247 mm`;
            } else if (height === 1440) {
              return `1387 mm`;
            }
          } else if (block === BLOCK_ROMA) {
            if (height === 1400) {
              return `1317 mm`;
            } else if (height === 1600) {
              return `1527 mm`;
            }
          }
        }
      };

      const getGateHeight = () => {
        if (model === MODEL_P82) {
          if (block === BLOCK_GORC) {
            if (height === 1280) {
              return '1220 mm';
            } else if (height === 1440) {
              return '1410 mm';
            }
          } else if (block === BLOCK_ROMA) {
            if (height === 1400) {
              return '1302 mm';
            } else if (height === 1600) {
              return '1492 mm';
            }
          }
        } else if (model === MODEL_PS004) {
          if (block === BLOCK_GORC) {
            if (height === 1280) {
              return '1247 mm';
            } else if (height === 1440) {
              return '1387 mm';
            }
          } else if (block === BLOCK_ROMA) {
            if (height === 1400) {
              return '1349 mm';
            } else if (height === 1600) {
              return `1527 mm`;
            }
          }
        }
      };

      const getExtraGateHeight = () => {
        if (model === MODEL_P82) {
          if (block === BLOCK_GORC) {
            return null;
          } else if (block === BLOCK_ROMA) {
            if (height === 1400) {
              return '1310 mm';
            } else if (height === 1600) {
              return '1505 mm';
            }
          }
        } else if (model === MODEL_PS004) {
          if (block === BLOCK_GORC) {
            return null;
          } else if (block === BLOCK_ROMA) {
            if (height === 1400) {
              return '1317 mm';
            } else {
              return null;
            }
          }
        }
      };

      const getVisualisation = () => {
        if (block === BLOCK_ROMA) {
          return (
              `https://kns-clearance.s3.eu-central-1.amazonaws.com/img/${model}-360-${height}.png`
          )
        } else if (block === BLOCK_GORC) {
          return (
              `https://kns-clearance.s3.eu-central-1.amazonaws.com/img/${model}-380-${height}.png`
          )
        }
      };

      const getTechnical = () => {
        if (block === BLOCK_ROMA) {
          return (
              `https://kns-clearance.s3.eu-central-1.amazonaws.com/img/${model}-360-${height}-technical.png`
          )
        } else if (block === BLOCK_GORC) {
          return (
              `https://kns-clearance.s3.eu-central-1.amazonaws.com/img/${model}-380-${height}-technical.png`
          )
        }
      };


      const newResponse = {
        visualisation: getVisualisation(),
        technical: getTechnical(),
        gateType: getGateType(),
        extraGateType: getExtraGateType(),
        spanHeight: getSpanHeight(),
        gatewayHeight: getGatewayHeight(),
        gateHeight: getGateHeight(),
        extraGateHeight: getExtraGateHeight(),
      };
      dispatch({type: SET_RESPONSE, response: newResponse});
    }
  };

  const HeightSelect = () => {
    if (block === BLOCK_ROMA) {
      return (
          <div className="form-group">
            <label htmlFor="heightSelect">Wybierz wysokość</label>
            <select
                className={`form-control`}
                id={'heightSelectConcreteBlocks'}
                value={height || ''}
                onChange={heightChange}
                required
            >
              <option value="">Wybierz wysokość</option>
              <option value={1400}>1400 mm</option>
              <option value={1600}>1600 mm</option>
            </select>
          </div>
      )
    } else if (block === BLOCK_GORC) {
      return (
          <div className="form-group">
            <label htmlFor="heightSelect">Wybierz wysokość</label>
            <select className={`form-control`}
                    id={'heightSelectConcreteBlocks'}
                    value={height || ''}
                    onChange={heightChange}
                    required
            >
              <option value="">Wybierz wysokość</option>
              <option value={1280}>1280 mm</option>
              <option value={1440}>1440 mm</option>
            </select>
          </div>
      )
    } else {
      return (
          <div className="form-group invisibleHeightSelectContainer">
            <label htmlFor="heightSelect">Wybierz wysokość</label>
            <select className={`form-control`}
                    id={'heightSelectConcreteBlocks'}
                    value={height || ''}
                    onChange={heightChange}
                    required
            >
            </select>
          </div>
      )
    }
  };

  return (
      <header id={'formSectionConcreteBlocks'}>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col'}>
              <div className={'backToFirstPageContainer'}>
                <button
                    type={'button'}
                    className={'backToFirstPage'}
                    onClick={goBack}
                >
                  Wróć
                </button>
              </div>
              <form id={'formConcreteBlocks'} onSubmit={onSubmit}>
                <div id={'formHeaderConcreteBlocks'}>
                  <h1>Tytuł</h1>
                </div>
                <div id={'formInputsConcreteBlocks'}>
                  <div className="form-group">
                    <label htmlFor="modelSelect">Wybierz model</label>
                    <select className={`form-control`}
                            id={'modelSelectConcreteBlocks'}
                            onChange={modelChange}
                            value={model || ''}
                            required
                    >
                      <option value='' disabled>Wybierz model</option>
                      <option value={MODEL_P82}>P82</option>
                      <option value={MODEL_PS004}>PS004</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="blockSelect">Wybierz bloczek</label>
                    <select className={`form-control`}
                            id={'blockSelect'}
                            value={block || ''}
                            onChange={blockChange}
                            required
                    >
                      <option value='' disabled>Wybierz bloczek</option>
                      <option value={BLOCK_ROMA}>360 - Roma</option>
                      <option value={BLOCK_GORC}>380 - Gorc</option>
                    </select>
                  </div>
                  <HeightSelect/>
                  <div>
                    <button className={'btn btn-primary btn-icon-left'} type={'submit'}>
                      <FontAwesomeIcon icon={faPaperPlane}/>
                      <span>Znajdź</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </header>
  );
};

export default FormConcreteBlocks;
