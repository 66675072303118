import React from 'react';
import './ProjectConcreteBlocks.css';
import {useSelector} from "react-redux";
import PdfBtn from "../Pdf/PdfBtn";

const concreteBlocks = 'concreteBlocks';

const ProjectConcreteBlocks = () => {
  const chosenOption = useSelector(state => state.app.chosenOption);
  const response = useSelector(state => state.app.response);

  if (!response || chosenOption !== concreteBlocks) {
    return null;
  } else {

    return (
        <main>
          <div id={'concreteBlocksResult'}>
            <div className={'container-xl'}>
              <div className={'row'}>
                <div className={'col'}>
                  <div className={'concreteBlocksImage'}>
                    <img className={'concreteBlocksVisualisation'}
                         src={response.visualisation}
                         alt={''}
                    />
                  </div>
                  <div className={'tableContainer'}>
                    <table id={'concreteBlocksDataTable'}>
                      <thead>
                      <tr>
                        <th>Wysokość przęsła</th>
                        <th>Wysokość furtki</th>
                        <th>{response.gateType}</th>
                        <th>{response.extraGateType}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{response.spanHeight}</td>
                        <td>{response.gatewayHeight}</td>
                        <td>{response.gateHeight}</td>
                        <td>{response.extraGateHeight}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={'downloadPdfContainer'}>
                    <PdfBtn/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
    );
  }
};

export default ProjectConcreteBlocks;
