const PdfHtml = (body) => {
  return `<html lang="pl">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
              integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
              crossOrigin="anonymous"/>
        <style>
        </style>
      </head>
      <body>
        ${body}
      </body>
      </html>
  `;
};

export default PdfHtml;