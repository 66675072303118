import {connect} from 'react-redux';
import ProjectConcreteBlocks from '../../components/ProjectConcreteBlocks/ProjectConcreteBlocks';

const mapStateToProps = (state) => ({

    response: state.app.response,
    model: state.app.model,
});

export default connect(
    mapStateToProps,
)(ProjectConcreteBlocks);
