import React from 'react';
import './ErrorResponse.css';
import PropTypes from 'prop-types';

const ErrorResponse = ({errorResponse}) => {
  if (!errorResponse) {
    return null;
  }

  return (
    <section id={'errorResponse'}>
      <div className={'container-xl'}>
        <div className={'row'}>
          <div className={'col'}>
            <p>{errorResponse}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

ErrorResponse.propTypes = {
  errorResponse: PropTypes.string,
};

export default ErrorResponse;
