import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import './Form.css';
import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN,
  fencesTypes
} from '../FenceTypesConstants';
import {models, modelsData} from '../ModelsConstants';
import {useDispatch, useSelector} from "react-redux";
import {SET_BLOCK, SET_CHOSEN_OPTION, SET_HEIGHT, SET_MODEL, SET_RESPONSE, setErrorResponse} from "../actions";

const Form = () => {
  const chosenOption = useSelector(state => state.app.chosenOption);
  const constantHeightsOption = 'constantHeights';

  const model = useSelector(state => state.app.model);
  const height = useSelector(state => state.app.height);

  const dispatch = useDispatch();

  const [gatewayHeightsWithHeightProvidedByUser, setGatewayHeightsWithHeightProvidedByUser] = useState([]);

  if (chosenOption !== constantHeightsOption) {
    return null;
  }

  const goBack = () => {
    dispatch({type: SET_CHOSEN_OPTION, chosenOption: null});
    dispatch({type: SET_MODEL, model: null});
    dispatch({type: SET_HEIGHT, height: null});
    dispatch({type: SET_BLOCK, block: null});
    dispatch({type: SET_RESPONSE, response: null})
  };

  const modelChange = (e) => dispatch({type: SET_MODEL, model: e.target.value});

  const heightChange = (e) => dispatch({type: SET_HEIGHT, height: parseInt(e.target.value)});

  const findClosest = (type, height) => {
    if (height <= 0) {
      console.error('gatewayHeight <= 0');

      return null;
    }

    if (!modelsData[type].hasOwnProperty(model)) {
      console.error('heightGates.hasOwnProperty(model)');

      return null;
    }

    let closestHeight = null;

    let x = 0;
    while (height >= closestHeight) {
      closestHeight = modelsData[type][model][x];
      x++;
    }

    closestHeight = (modelsData[type][model][x - 2]) ? modelsData[type][model][x - 2] : null;

    return closestHeight;
  };

  const areRangesValid = (closestGateHeightWithMinimumDistance, closestGatewayHeight) => {
    return Math.abs(closestGateHeightWithMinimumDistance - closestGatewayHeight) < modelsData.ranges[model].min || Math.abs(closestGateHeightWithMinimumDistance - closestGatewayHeight) > modelsData.ranges[model].max;
  };

  const createGatewayHeightsWithHeightProvidedByUser = (heightVisibleNow) => {
    const arr = [];

    modelsData.gateway[model].forEach((height) => {
      arr.push({
        height: height,
        isVisibleNow: height === heightVisibleNow,
      });
    });

    arr.sort((a, b) => a.height - b.height);

    setGatewayHeightsWithHeightProvidedByUser(arr);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // for gateSelfSupporting is the same
    const closestGateHeight = findClosest(FENCE_TYPE_GATE_TILTING, height);
    const clearances = modelsData.clearances[model];
    const closestGateHeightWithMinimumDistance = closestGateHeight + clearances.min;
    const closestGatewayHeight = findClosest(FENCE_TYPE_GATEWAY, closestGateHeightWithMinimumDistance);
    const closestSpanHeight = findClosest(FENCE_TYPE_SPAN, closestGateHeightWithMinimumDistance);

    if (areRangesValid(closestGateHeightWithMinimumDistance, closestGatewayHeight)) {
      setErrorResponse('Nie udało się znależć.');

      return;
    }

    createGatewayHeightsWithHeightProvidedByUser(closestGatewayHeight);

    const response = {
      [FENCE_TYPE_GATEWAY]: {
        closestHeight: closestGatewayHeight,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        closestHeight: closestGateHeight,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        closestHeight: closestGateHeight,
      },
      [FENCE_TYPE_SPAN]: {
        closestHeight: closestSpanHeight,
      },
    };

    fencesTypes.forEach((fenceType) => {
      response[fenceType].clearance = (
          <span className={'responseVisualisationClearanceNumber'}>
          {modelsData.clearances[model].min} mm - {modelsData.clearances[model].max} mm
        </span>
      );
    });

    dispatch({type: SET_RESPONSE, response: response});

  };

  const ModelSelect = () => {
    const options = models.map((model) => (<option key={model} value={model}>{model}</option>));

    return (
        <div className="form-group selectInputContainer">
          <label htmlFor="modelSelect">Wybierz model</label>
          <select className={`form-control`} id={'modelSelect'} value={model ?? ''} onChange={modelChange}>
            <option value={''} disabled>Wybierz model</option>
            {options}
          </select>
        </div>
    );
  };
  return (
      <header id={'formSection'}>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col'}>
              <div className={'backToFirstPageContainer'}>
                <button
                    type={'button'}
                    className={'backToFirstPage'}
                    onClick={goBack}
                >
                  Wróć
                </button>
              </div>
              <form id={'form'} onSubmit={onSubmit}>
                <div id={'formHeader'}>
                  <h1>Standardowe wysokości</h1>
                </div>
                <div id={'formInputs'}>
                  <ModelSelect/>
                  <div className={'form-group selectInputContainer'}>
                    <label htmlFor={'height'}>Wysokość (mm)</label>
                    <input
                        type={'number'}
                        className={'form-control'}
                        id={'height'}
                        step={'10'}
                        placeholder={'Wysokość (mm)'}
                        onChange={heightChange}
                        value={height ?? ''}
                    />
                  </div>
                  <div>
                    <button className={'btn btn-primary btn-icon-left'} type={'submit'}>
                      <FontAwesomeIcon icon={faPaperPlane}/>
                      <span>Znajdź</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </header>
  );
};

export default Form;
