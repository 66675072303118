export const SET_HEIGHT = 'SET_HEIGHT';
export const SET_MODEL = 'SET_MODEL';
export const SET_BLOCK = 'SET_BLOCK';
export const SET_RESPONSE = 'SET_RESPONSE';
export const SET_ERROR_RESPONSE = 'SET_ERROR_RESPONSE';
export const SET_CHOSEN_OPTION = 'SET_CHOSEN_OPTION';

export const setHeight = (height) => ({type: SET_HEIGHT, height: height});

export const setModel = (model) => ({type: SET_MODEL, model: model});

export const setBlock = (block) => ({type: SET_BLOCK, block: block});

export const setResponse = (response) => ({type: SET_RESPONSE, response: response});

export const setErrorResponse = (errorResponse) => ({type: SET_ERROR_RESPONSE, errorResponse: errorResponse});
