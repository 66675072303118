import React from 'react';
import ClosestHeights from './ClosestHeights';
import RangeBtns from './RangeBtns';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp, faInfo} from '@fortawesome/free-solid-svg-icons';
import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN
} from "../../FenceTypesConstants";
import {useSelector} from "react-redux";
import {modelsData} from '../../ModelsConstants';
import './Project.css';

const Project = () => {
  const model = useSelector(state => state.app.model);
  const modelImg = model.toLowerCase();
  const response = useSelector(state => state.app.response);

  const clearances = modelsData.clearances[model];

  return (
      <div>
        <div className={'container-xl'}>
          <div className={'row'}>
            <div className={'col'}>
              <RangeBtns/>
              <div>
                <div id={'responseVisualisations'}>
                  <article id={'responseGateSelfSupporting'}>
                    <h1 className={'responseVisualisationHeader'}>Brama samonośna</h1>
                    <img className={'responseVisualisationImg'}
                         alt={''}
                         src={`img/${modelImg}/${FENCE_TYPE_GATE_SELF_SUPPORTING}.png`}/>
                    <div className={'responseVisualisationClearance'}>
                      <FontAwesomeIcon icon={faInfo} className={'responseVisualisationIcon'}/>
                      <span>Prześwit od podłoża {response[FENCE_TYPE_GATE_SELF_SUPPORTING].clearance}</span>
                    </div>
                  </article>
                  <article id={'responseGateTilting'}>
                    <h1 className={'responseVisualisationHeader'}>Brama uchylna</h1>
                    <img className={'responseVisualisationImg'}
                         alt={''}
                         src={`img/${modelImg}/${FENCE_TYPE_GATE_TILTING}.png`}/>
                    <div className={'responseVisualisationClearance'}>
                      <FontAwesomeIcon icon={faInfo} className={'responseVisualisationIcon'}/>
                      <span>Prześwit od podłoża <br/>{response[FENCE_TYPE_GATE_TILTING].clearance}</span>
                    </div>
                  </article>
                  <article id={'responseGateway'}>
                    <h1 className={'responseVisualisationHeader'}>Furtka</h1>
                    <img className={'responseVisualisationImg'}
                         alt={''}
                         src={`img/${modelImg}/${FENCE_TYPE_GATEWAY}.png`}/>
                    <div className={'responseVisualisationClearance'}>
                      <FontAwesomeIcon icon={faInfo} className={'responseVisualisationIcon'}/>
                      <span>Prześwit od podłoża <br/>{response[FENCE_TYPE_GATEWAY].clearance}</span>
                    </div>
                  </article>
                  <article id={'responseSpan'}>
                    <h1 className={'responseVisualisationHeader'}>Przęsło</h1>
                    <img className={'responseVisualisationImg'}
                         alt={''}
                         src={`img/${modelImg}/${FENCE_TYPE_SPAN}.png`}/>
                    <div className={'responseVisualisationClearance'}>
                      <FontAwesomeIcon icon={faInfo} className={'responseVisualisationIcon'}/>
                      <span>Prześwit od podłoża <br/>{response[FENCE_TYPE_SPAN].clearance}</span>
                    </div>
                  </article>
                  <div id="responseSuggestedHeight">
                      <FontAwesomeIcon icon={faChevronUp}/>
                      <span>sugerowana<br/>wysokość<br/>ogrodzenia<br/>{response[FENCE_TYPE_GATE_SELF_SUPPORTING].closestHeight + clearances.min}</span>
                      <FontAwesomeIcon icon={faChevronDown}/>
                  </div>
                </div>
                <ClosestHeights response={response}/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Project;
