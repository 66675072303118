import {connect} from 'react-redux';
import ErrorResponse from '../components/ErrorResponse';

const mapStateToProps = (state) => ({
    errorResponse: state.app.errorResponse,
});

export default connect(
    mapStateToProps,
)(ErrorResponse);
