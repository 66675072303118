import React from 'react';
import Project from '../containers/Project/Project';
import ErrorResponse from '../containers/ErrorResponse';
import {useSelector} from "react-redux";

const constantHeights = 'constantHeights';

const ConstantHeightsApp = () => {
  const chosenOption = useSelector(state => state.app.chosenOption);
  const response = useSelector(state => state.app.response);

  if (!response || chosenOption !== constantHeights) {
    return null;
  }

  return (
      <main>
        <ErrorResponse/>
        <Project/>
      </main>
  );
};

export default ConstantHeightsApp;
